import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { faArrowCircleRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import '../../../../assets/grandbankpage.css'
import '../../../../assets/custom.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PixelsService from '../../../../services/pixels';
import GrandBankFooter from './grandBankFooter';
import AxiosWrapper from '../../../../services/axios';


function GrandBankPage() {

    const applicationResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const setShowHeader = useStoreActions(action => action.siteSettingsModel.setShowHeader);
    const setGrandBankPixels = useStoreActions(action => action.setGrandBankPixels);
    const GrandBankPixel = useStoreState(state => state.grandBankPixels)
    const PixelService = new PixelsService();
    const GBToltip = useStoreState(state => state.GBText.tooltip)
    const setting = useStoreState(state => state.siteSettingsModel);
    const client = new AxiosWrapper();
 

    //Result event sended to GA
    useEffect(() => {
        if(!GrandBankPixel){
            PixelService.pageView("/grandbank").event("google", setting.mainSettings.Flow, 'GrandBankPage')

            setGrandBankPixels(true)
        }
        document.body.style.backgroundColor = "#f0ece9"
        setShowHeader(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[])

    function clickRedirect(){
        let id = applicationResult.ref_number;

        client.post("update-gb-redirect-status",{"interstitialPage" : true, "ApplicantId" : id, "isRedirected" : true});
    }

    return (
        <>
        <div className='row GBHeader mb-4 p-3'>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4 BGHader'>
                <div className='row'>
                    <div className='col-5'>
                        <img className='w-100' src='/grandbanklogo.png' alt='Grand bank Logo'/>
                    </div>
                    <div className='col-7'>
                        <ul className='p-0' >
                            <li><a rel="noopener noreferrer" href={setting.productSettings.PrivacyUrl} target='_blank'>Privacy</a></li>
                            <li><a rel="noopener noreferrer" href="https://grandbank.wpengine.com/" target='_blank'>Learn More</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container" >
            <div className='row'>
           <div className="col-xs-12 col-md-6 col-lg-6 ">
                <img className='w-100' src='/Grand-Bank-Art.png' alt='Grand Bank' />
           </div>
           <div className="col-xs-12 col-md-6 col-lg-6 ">
                <div className='row'>
                    <div className='col-12'>
                        <p style={{fontWeight: "500", fontSize : "14pt"}}>
                            Grand360 is the digital banking tool that makes it easy
                            to manage your money and reach your financial goals.<br/>
                            <strong>Here’s all you get:</strong>
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 p-0'>
                        <ul style={{listStyle : "none"}}>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Up to $150 in Cover Me Now</label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.upTo}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Checking & Savings Bundle</label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.checkingBundle}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Easy Access </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.easyAccess}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Get paid up to 2 days early </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.paidEarly}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>More perks </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.morePerk}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Satisfaction Guarantee </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.moneyBack}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Automatic Savings Tool </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.automaticSaving}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Joint Accounts </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.jointAccount}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Simulator and Credit Score Monitoring</label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.freeMonitoring}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Free Financial Wellness Tool </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.wellnessTool}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>Deposits are Safe </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.safeSound}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                            <li className='mb-1'>
                                <span className='mr-3'><FontAwesomeIcon color='#ed4d4d'  icon={faArrowCircleRight}/></span>
                                <label className='GBtextList'>No credit check </label>
                                <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip   id="age-tooltip" >{GBToltip.noCheck}</Tooltip>}>
                                    <span className='GBQuestionMark'><FontAwesomeIcon color='#385d79' size='lg' icon={faQuestionCircle}/></span>
                                </OverlayTrigger>
                            </li>
                        </ul>
                    </div>
                </div>
                    
                </div>
            </div>
            <div className='col-12 mb-3'>
                <div className='col-xs-12 col-sm-12 col-md-4 offset-md-4 col-lg-4 offset-lg-4 text-center'>
                    <form action={applicationResult.GrandbankUrl} method="POST" target='_blank'>
                        <input type="hidden" value={applicationResult.GBAuthId} name="authid"/>
                        <button type='submit' className='btn btn-round btn-block tempClass' onClick={() => {PixelService.event("google", setting.mainSettings.Flow, "GoToGrandBankButtonClick"); clickRedirect()}} style={{backgroundColor : "#f04d4e", fontWeight : "700"}}>Let’s Go!</button>
                    </form>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='container p-0'>
                <div className='col-12 p-0 mb-0'>
                    <div className='col-12 mb-1 '>
                        <p className='text-left' style={{fontWeight : "300", fontSize: "12pt"}}>By clicking Let’s Go!, you authorize the sharing of your personal information with Grand Bank.</p>
                        <p className='text-left' style={{fontWeight : "300", fontSize: "12pt"}}>
                            Receive all the benefits of Grand360 for just $5 a month.
                        </p>
                    </div>
                </div>
           </div>
        </div>
        <div className='row mb-0'>
            <div className='col-12' style={{backgroundColor : "#375e7b"}}>
                <p className='text-center mb-1 mt-1' style={{color: "white", fontWeight : "500", fontSize: "19pt"}}>
                    No minimum deposit | Account access 24/7 | 40,000 surcharge free ATMs nationwide
                </p>
            </div>
        </div>
        <GrandBankFooter />
        <div className="row text-center">
            <div className='container p-0' >
                <div className='col-12 mb-0'  >
                    <div className="col-12 reminder mb-4 mt-4" style={{textAlign: "left", border: "1px solid #c9cdd5", boxShadow : "rgb(0 0 0 / 30%) 4px 4px"}}>
                        <small style={{lineHeight: "normal", textDecoration: "underline"}}>
                            The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, 
                            national origin, sex, marital status, age (provided that the applicant has the capacity to enter into a binding contract); 
                            because all or part of the applicant's income is derived from a public assistance program; or because the applicant has in good faith exercised his/her 
                            right under the Consumer Credit Protection Act. The federal agency that administers the compliance with this law concerning this creditor is the Division of Depositor and Consumer Protection, 
                            National Center for Consumer and Depositor Assistance, Federal Deposit Insurance Corporation, 1100 Walnut Street, Box #11, Kansas City, MO 64106.
                        </small>
                    </div>
                </div>
            </div>
        </div>
        
        </>
    );

}

export default GrandBankPage;